//client/src/components/recommendation/dogRecommendationLogic.js

export function calculateDogRecommendations(answers) {
    const characteristics = {};
  
    console.log('Розрахунок характеристик для котів на основі відповідей:', JSON.stringify(answers, null, 2));
    
    // Рекомендації на основі типу харчування
    if (answers.dietType?.value.includes('grainFree')) {
      characteristics['priznachennja'] = 12; // id для "Беззерновий"
    }
  
    if (answers.dietType?.value.includes('hypoallergenic')) {
      characteristics['priznachennja'] = 11; // id для "Гіпоалергенний"
    }
  
    if (answers.digestiveIssues?.value.length > 0) {
      characteristics['priznachennja'] = 11; // id для "При чутливому травленні"
    }
  
    if (answers.neutered?.value === 'yes') {
      characteristics['priznachennja'] = 8; // id для "Для кастрованих/стерилізованих"
    }
  
    console.log('Згенеровані характеристики для MongoDB-запиту для собак:', JSON.stringify(characteristics, null, 2));
  
    return characteristics;
  }
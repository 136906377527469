//client/src/components/recommendation/catRecommendationLogic.js

export function calculateCatRecommendations(answers, noResultsFlag = false) {
  const characteristics = {};

  // Вік
  if (answers.age?.value === '0-12') {
    characteristics['dljaKogo'] = 1; // id для "Котикам"
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 7]; // id для "Для малюків, для вагітних та годуючих самок"
    characteristics['vk'] = 4; // id для "Кошенята"
  } else if (answers.age?.value === '1-7') {
    characteristics['dljaKogo'] = 1; // id для "Котикам"
  } else if (answers.age?.value === '7+') {
    characteristics['dljaKogo'] = 1; // id для "Котикам"
  }

  // Порода
  if (!noResultsFlag) {
    if (answers.breed?.value === 'hairless') {
      characteristics['poroda'] = 3; // id для "Сфінкс"
    } else if (answers.breed?.value === 'exotic') {
      characteristics['poroda'] = 4; // id для "Абіссинська"
    } else if (answers.breed?.value === 'shorthair') {
      characteristics['poroda'] = 9; // id для "Для усіх порід"
    } else if (answers.breed?.value === 'longhair') {
      characteristics['poroda'] = 9; // id для "Для усіх порід"
    } else if (answers.breed?.value === 'outbred') {
      characteristics['poroda'] = 4; // id для "Для усіх порід"
    }
  }

  // Довжина шерсті
  if (answers.furType?.value === 'longHaired') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 12, 17]; // id для "Для виведення шерсті зі шлунку" та "Здоров’я шкіри та блиск шерсті"
  } else if (answers.furType?.value === 'shortHaired') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 17]; // id для "Здоров’я шкіри та блиск шерсті"
  }

  // Схильність до утворення ковтунів
  if (answers.matting?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 17]; // id для "Здоров’я шкіри та блиск шерсті"
  }

  // Тип статури
  if (answers.bodyType?.value === 'emaciated' || answers.bodyType?.value === 'thin') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 24]; // id для "Укріплення захисних функцій організму"
  } else if (answers.bodyType?.value === 'overweight' || answers.bodyType?.value === 'obesity') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 33]; // id для "Для підтримки опорно-рухового апарату"
  }

  // Стерилізований/кастрований
  if (answers.neutered?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8]; // id для "Для кастрованих/стерилізованих"
  }

  // Народжує кішка
  if (answers.birthFrequency?.value === 'never') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8]; // id для "Для кастрованих/стерилізованих"
  } else if (['3_times', '2_times', '1_time', 'rarely'].includes(answers.birthFrequency?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 7, 23]; // id для "Для малюків, для вагітних та годуючих самок" та "Підготовка до пологів"
  }

  // Хронічні захворювання
  if (answers.chronicIllness?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 15]; // id для "Ефективний імуномодулятор"
  }

  // Прийом ліків
  if (answers.medications?.value?.includes('insulin') ||
      answers.medications?.value?.includes('nsaids') ||
      answers.medications?.value?.includes('thyroxin') ||
      answers.medications?.value?.includes('steroids') ||
      answers.medications?.value?.includes('contraceptives')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 15]; // id для "Ефективний імуномодулятор"
  }

  // Проблеми з травленням
  if (answers.digestiveIssues?.value?.includes('constipation') ||
      answers.digestiveIssues?.value?.includes('diarrhea') ||
      answers.digestiveIssues?.value?.includes('vomiting')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 11, 14]; // id для "При чутливому травленні" + "Профілактика порушень ШКТ"
  }

  // Алергії/непереносимість
  if (answers.allergies?.value === 'yes') {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 1]; // id для "При схильності до алергій"
  }

  // Апетит
  if (answers.appetite?.value?.includes('pickySometimes')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 10]; // id для "Для вибагливих"
  }
  if (answers.appetite?.value?.includes('veryPicky')) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 10]; // id для "Для вибагливих"
  }

  // Смакові вподобання
  if (answers.tastePreferences?.value?.includes('grainFree')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 1]; // id для "Беззерновий"
  }
  if (answers.tastePreferences?.value?.includes('withoutChicken')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 5]; // id для "Без курочки"
  }
  if (answers.tastePreferences?.value?.includes('meat')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 3]; // id для "На основі м'яса"
  }
  if (answers.tastePreferences?.value?.includes('fish')) {
    characteristics['tipKormu'] = [...(characteristics['tipKormu'] || []), 4]; // id для "На основі риби"
  }

  // Вода
  if (['30-50', '50-100', '100-150', '150-200', '200-250', '250-300', 'notTracking'].includes(answers.waterIntake?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 22]; // id для "Профілактика сечокам’яної хвороби"
  }

  // Тип води
  if (['tapWater', 'filteredWater', 'bottledWater', 'specialCatWater', 'wellWater'].includes(answers.waterType?.value)) {
    characteristics['priznachennja'] = [...(characteristics['priznachennja'] || []), 8]; // id для "Для кастрованих/стерилізованих"
  }

  // Тип харчування
  if (answers.dietType?.value?.some(type => ['dryFood', 'wetFood', 'homemade', 'tableScraps'].includes(type))) {
    characteristics['vidKormu'] = [...(characteristics['vidKormu'] || []), 1, 2, 3, 4]; // id для "Основне харчування", "Кормова добавка", "Ласощі", "Фітоміни"
  }

  return characteristics;
}

// home/servers/feedcraft/client/src/services/productService.js

import axios from 'axios';

// Функція для отримання продуктів з локальної бази даних
async function fetchProductsLocally(characteristics = {}, setProducts) {
  try {
    // Перевірка на порожні характеристики
    if (Object.keys(characteristics).length === 0) {
      console.warn('Характеристики для запиту порожні. Запит не буде надіслано.');
      return;
    }

    console.log('Формування запиту до бекенду з такими характеристиками:', JSON.stringify(characteristics));

    // Відправлення запиту на сервер
    const response = await axios.post('https://feedcraft.space/api/products/local', { characteristics });

    console.log('Отримана відповідь з бекенду:', response.data.products); // Логування відповіді з бекенду

    // Перевіряємо, чи дані отримані правильно
    if (!response.data || !Array.isArray(response.data.products)) {
      console.error('Невірний формат відповіді з бекенду:', response.data);
      return;
    }

    // Фільтрація отриманих продуктів
    const filteredProducts = filterProducts(response.data.products);

    // Передача відфільтрованих продуктів у компонент
    setProducts(filteredProducts);

    console.log('Відфільтровані продукти:', filteredProducts); // Логування відфільтрованих продуктів
  } catch (error) {
    console.error('Помилка при отриманні продуктів з бекенду:', error.message);
  }
}

// Функція для фільтрації отриманих продуктів за `vidKormu`
function filterProducts(products) {
  console.log('Починаємо фільтрацію продуктів:', products);
  const filteredProducts = {
    vidKormu1: [],
    vidKormu2: [],
    vidKormu3: [],
    vidKormu4: []
  };

  // Розподіл продуктів по категоріях vidKormu
  products.forEach(product => {
    const vidKormu = product.vidKormu;

    if (!vidKormu || typeof vidKormu.id !== 'number') {
      console.warn('Невідомий тип vidKormu або відсутній id:', product);
      return;
    }

    const vidKormuId = vidKormu.id;

    console.log(`Обробка продукту з vidKormu.id = ${vidKormuId}:`, product);

    switch (vidKormuId) {
      case 1:
        if (filteredProducts.vidKormu1.length < 8) {
          filteredProducts.vidKormu1.push(product);
          console.log('Додано до vidKormu1:', product);
        }
        break;
      case 2:
        if (filteredProducts.vidKormu2.length < 2) {
          filteredProducts.vidKormu2.push(product);
          console.log('Додано до vidKormu2:', product);
        }
        break;
      case 3:
        if (filteredProducts.vidKormu3.length < 2) {
          filteredProducts.vidKormu3.push(product);
          console.log('Додано до vidKormu3:', product);
        }
        break;
      case 4:
        if (filteredProducts.vidKormu4.length < 2) {
          filteredProducts.vidKormu4.push(product);
          console.log('Додано до vidKormu4:', product);
        }
        break;
      default:
        console.warn('Невідомий тип vidKormu:', vidKormuId);
    }
  });

  // Об'єднання відфільтрованих результатів
  const result = [
    ...filteredProducts.vidKormu1,
    ...filteredProducts.vidKormu2,
    ...filteredProducts.vidKormu3,
    ...filteredProducts.vidKormu4
  ];

  console.log('Результат фільтрації:', result);
  return result;
}

// Функція для формування запиту на основі відповідей опитувальника
function generateRecommendations(petType, answers) {
  console.log('Формування рекомендацій для тварини типу:', petType, 'з відповідями:', answers);
  
  let characteristics = {};
  if (petType === 'cat') {
    characteristics = calculateCatRecommendations(answers); // Формуємо рекомендації для котів
    console.log('Сформовані характеристики для кота:', characteristics);
  } else if (petType === 'dog') {
    characteristics = calculateDogRecommendations(answers); // Формуємо рекомендації для собак
    console.log('Сформовані характеристики для собаки:', characteristics);
  } else {
    console.warn('Невідомий тип тварини:', petType);
    return [];
  }

  // Перевіряємо, чи правильно сформовані характеристики перед відправкою запиту
  if (!characteristics || Object.keys(characteristics).length === 0) {
    console.warn('Характеристики не сформовані належним чином:', characteristics);
    return [];
  }

  return characteristics;
}

// Експорт функцій
export { fetchProductsLocally, generateRecommendations };

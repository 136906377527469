// home/servers/feedcraft/client/src/recommendation/dogRecommendationTextLogic.js

export function generateDogRecommendationText(answers) {
  
    if (answers.age?.value === '0-12') {
      text += " Оскільки ваш собака ще щеня, йому потрібно особливе харчування, що сприяє зростанню і розвитку.";
    } else if (answers.age?.value === '7+') {
      text += " Ваш собака уже досяг зрілого віку, тому важливо забезпечити його харчуванням, яке підтримує здоров'я і активність.";
    }
  
    if (answers.neutered?.value === 'yes') {
      text += " Оскільки ваш собака кастрований/стерилізований, ми рекомендуємо корм, який допомагає контролювати вагу і підтримувати здоров'я.";
    }
  
    if (answers.bodyType?.value === 'emaciated' || answers.bodyType?.value === 'thin') {
      text += " Ваш собака має недостатню вагу, тому рекомендується корм, багатий на поживні речовини для підтримки імунітету.";
    } else if (answers.bodyType?.value === 'overweight' || answers.bodyType?.value === 'obesity') {
      text += " Оскільки ваш собака має надлишкову вагу, ми рекомендуємо дієтичний корм, який допомагає контролювати вагу та підтримувати здоров'я.";
    }
  
    if (answers.allergies?.value === 'yes') {
      text += " Оскільки ваш собака має алергії, ми рекомендуємо корм з гіпоалергенними інгредієнтами.";
    }
  
    if (answers.digestiveIssues?.value.includes('constipation')) {
      text += " Враховуючи проблеми з запорами, рекомендовано харчування, що покращує травлення.";
    }
  
    if (answers.appetite?.value.includes('veryPicky')) {
      text += " Оскільки ваш собака вибагливий в їжі, ми рекомендуємо корм з особливо привабливим смаком.";
    }
  
    return text;
  }
  
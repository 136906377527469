//home/servers/feedcraft/client/src/pages/SurveyFlow.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Radio, Input, Card, Progress, Checkbox, Select, Spin, Slider, InputNumber, Row, Col } from 'antd';
import { CaretRightOutlined, CaretLeftOutlined } from '@ant-design/icons'; // Імпортуємо іконки
import axios from 'axios';
import { catQuestions } from '../components/questions/CatQuestions';
import { dogQuestions } from '../components/questions/DogQuestions';
import { fetchProductsLocally } from '../services/productService';
import { calculateCatRecommendations } from '../components/recommendation/catRecommendationLogic';
import { calculateDogRecommendations } from '../components/recommendation/dogRecommendationLogic';
import SurveyResults from './SurveyResults';

// Функція для валідації email
const validateEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

// Компонент IntegerStep для введення ваги котика через слайдер
const IntegerStep = ({ value, onChange }) => {
  const [inputValue, setInputValue] = useState(value || 1);

  const handleChange = (newValue) => {
    setInputValue(newValue);
    onChange(newValue);
  };

  return (
    <Row>
      <Col span={12}>
        <Slider
          min={1}
          max={10}
          onChange={handleChange}
          value={typeof inputValue === 'number' ? inputValue : 0}
        />
      </Col>
      <Col span={4}>
        <InputNumber
          min={1}
          max={10}
          style={{ margin: '0 16px' }}
          value={inputValue}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
};

const SurveyFlow = () => {
  const [petType, setPetType] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [completed, setCompleted] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false); // Лоадер
  const [error, setError] = useState(null);

  const getDynamicQuestionText = (question) => {
    if (question && question.question.includes('(name)') && answers.name) {
      return question.question.replace('(name)', answers.name.value);
    }
    return question ? question.question : '';
  };

  const questions = petType === 'cat' ? catQuestions : petType === 'dog' ? dogQuestions : [];

  useEffect(() => {
    const question = questions[currentQuestionIndex];
    if (question && question.condition && !question.condition(answers)) {
      handleNext();
    }
  }, [currentQuestionIndex, answers, questions]);

  const handleNext = async () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      console.log('Зібрані відповіді:', answers);

      // Формуємо характеристики для MongoDB запиту в залежності від типу тварини
      let characteristics;
      if (petType === 'cat') {
        characteristics = calculateCatRecommendations(answers);
      } else if (petType === 'dog') {
        characteristics = calculateDogRecommendations(answers);
      }

      if (Object.keys(characteristics).length > 0) {
        console.log('Рекомендовані характеристики для продуктів:', characteristics);
        
        // Показуємо лоадер
        setLoading(true);

        // Отримуємо товари з локальної бази даних на основі характеристик
        await fetchProductsLocally(characteristics, setProducts);

        // Сховати лоадер після отримання відповідей
        setLoading(false);
      } else {
        console.warn('Характеристики для MongoDB-запиту порожні, запит не виконано.');
      }

      axios.post('/api/survey/response', { petType, answers })
        .then(response => {
          console.log('Відповіді успішно збережені', response.data);
          setCompleted(true);
        })
        .catch(error => {
          console.error('Помилка при збереженні відповідей:', error);
          setError('Помилка при збереженні відповідей. Будь ласка, спробуйте ще раз.');
        });
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
    }
  };

  const handleChange = (e) => {
    const value = e?.target?.value || e?.target?.checked || e;

    // Якщо це питання про кличку, то перетворюємо значення в рядок або порожній рядок
    const adjustedValue = questions[currentQuestionIndex]?.value === 'name' ? (value || '') : value;

    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questions[currentQuestionIndex]?.value]: {
        questionText: questions[currentQuestionIndex]?.question,
        value: adjustedValue
      }
    }));
  };

  const renderFirstQuestion = () => (
    <Card title="Опитувальник" bordered={false}>
      <Form layout="vertical">
        <Form.Item label="Який у вас хвостик?">
          <Radio.Group onChange={(e) => setPetType(e.target.value)} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Radio value="cat" style={{ flexBasis: '45%', marginBottom: '16px' }}>
              <div 
                style={{
                  textAlign: 'center',
                  border: '1px solid rgb(217, 217, 217)',
                  borderRadius: '8px',
                  padding: '8px'
                }}>
                <img
                  src="https://homefood.ua/content/images/30/0dlia-kartynok_2-78509377797457_+98c4c6c014.png"
                  alt="Котик"
                  style={{ width: '180px', height: 'auto', display: 'block', margin: '0 auto' }}
                />
                <div>Котик</div>
              </div>
            </Radio>
            <Radio value="dog" style={{ flexBasis: '45%', marginBottom: '16px' }}>
              <div 
                style={{
                  textAlign: 'center',
                  border: '1px solid rgb(217, 217, 217)',
                  borderRadius: '8px',
                  padding: '8px'
                }}>
                <img
                  src="https://homefood.ua/content/images/30/0dlia-kartynok_2-64573032813260_+9391af71c5.png"
                  alt="Песик"
                  style={{ width: '180px', height: 'auto', display: 'block', margin: '0 auto' }}
                />
                <div>Песик</div>
              </div>
            </Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Card>
  );

  const renderQuestion = (question) => {
    if (!question) return null;

    const dynamicQuestionText = getDynamicQuestionText(question);

    const isDigestiveIssuesQuestion = question.value === 'digestiveIssues';

    return (
      <Card title="Опитувальник" bordered={false}>
        <Form layout="vertical" key={currentQuestionIndex}>
          <Form.Item label={dynamicQuestionText}>
            {question && question.type === 'text' && (
              <Input
                placeholder="Ваша відповідь"
                onChange={handleChange}
                value={answers[question.value]?.value || ''}
              />
            )}
            {question && question.type === 'radio' && (
              <Radio.Group
                onChange={handleChange}
                value={answers[question.value]?.value || ''}
                style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
              >
                {question.options && question.options.map((option, index) => (
                  <Radio key={index} value={option.value} style={{ flexBasis: '45%', marginBottom: '16px' }}>
                    <div
                      style={{
                        border: option.image ? '1px solid rgb(217, 217, 217)' : 'none',
                        borderRadius: option.image ? '8px' : '0',
                        padding: option.image ? '8px' : '0'
                      }}
                    >
                      {option.image && (
                        <img
                          src={option.image}
                          alt={option.label}
                          style={{
                            width: '150px',
                            height: 'auto',
                            display: 'block',
                            margin: '0 auto'
                          }}
                        />
                      )}
                      <div style={{ textAlign: 'center' }}>{option.label}</div>
                    </div>
                  </Radio>
                ))}
              </Radio.Group>
            )}
            {question && question.type === 'checkbox' && (
              <Checkbox.Group
                onChange={(values) => handleChange({ target: { value: values } })}
                value={answers[question.value]?.value || []}
                style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}
              >
                {question.options && question.options.map((option, index) => {
                  const isNoneSelected = answers[question.value]?.value?.includes('no');
                  const isOtherSelected = answers[question.value]?.value?.length > 0 && !isNoneSelected;

                  return (
                    <Checkbox
                      key={index}
                      value={option.value}
                      disabled={(option.value !== 'no' && isNoneSelected) || (option.value === 'no' && isOtherSelected)}
                      style={{ flexBasis: '45%', marginBottom: '16px' }}
                    >
                      {option.label}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            )}
            {question && question.type === 'select' && (
              <Select
                showSearch
                placeholder="Оберіть варіант"
                optionFilterProp="children"
                onChange={(value) => handleChange({ target: { value } })}
                value={answers[question.value]?.value || ''}
                style={{ width: '100%' }}
              >
                {question.options && question.options.map((option, index) => (
                  <Select.Option key={index} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            )}
            {question && question.type === 'slider' && (
              <IntegerStep
                value={answers[question.value]?.value || 1}
                onChange={(value) => handleChange(value)}
              />
            )}
          </Form.Item>
          <div className="button-container" style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
              icon={<CaretLeftOutlined />} // Іконка "Назад"
            />
            <Button
              type="primary"
              onClick={() => {
                const isEmailQuestion = questions[currentQuestionIndex]?.value === 'email';
                const emailValue = answers['email']?.value || '';

                if (isEmailQuestion && !validateEmail(emailValue)) {
                  setError('Будь ласка, введіть коректний email');
                } else {
                  handleNext();
                  setError(null);
                }
              }}
              disabled={
                (!answers[questions[currentQuestionIndex]?.value]?.value && !isDigestiveIssuesQuestion) ||
                (isDigestiveIssuesQuestion && (answers['digestiveIssues']?.value?.length === 0))
              }
              icon={<CaretRightOutlined />} // Іконка "Вперед"
            />
          </div>
          <Progress
            percent={Math.round(((currentQuestionIndex + 1) / questions.length) * 100)}
            style={{ marginTop: '20px' }}
          />
        </Form>
      </Card>
    );
  };

  const renderResults = () => (
    <SurveyResults products={products} answers={answers} petType={petType} /> // Передаємо answers і petType для SurveyResults
  );

  return (
    <div style={{ maxWidth: completed ? '1000px' : '600px', margin: '0 auto', padding: '20px' }}>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : (
        !petType ? renderFirstQuestion() : completed ? renderResults() : renderQuestion(questions[currentQuestionIndex])
      )}
    </div>
  );
};

export default SurveyFlow;
